<template>
  <section class="section">
    <div class="container description">
      <div class="columns is-centered is-vcentered has-text-centered has-text-left-mobile is-9">
        <div class="column">
          <h2 class="title title-font" v-html="$t('desc_h2')" />
          <p class="subtitle subtitle-spaced" v-html="$t('desc_p')" />
        </div>
      </div>
    </div>
    <div class="container">
      <div class="columns is-vcentered">
        <div class="column is-5">
          <figure class="image">
            <picture>
              <source type="image/webp" srcset="@/assets/img/home/highlight1.webp">
              <source type="image/png" srcset="@/assets/img/home/highlight1.png">
              <img src="@/assets/img/home/highlight1.png" :alt="$t('highlight1_img')">
            </picture>
          </figure>
        </div>
        <div class="column is-5 is-offset-1">
          <h3 class="title is-spaced title-font" v-html="$t('highlight1_h3')" />
          <p class="subtitle subtitle-spaced" v-html="$t('highlight1_p')" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
    name: "Highlight1",
}
</script>

<style scoped>
.description {
    margin-top: 40px;
    margin-bottom: 60px;
}
.description .title {
    margin-bottom: 1rem;
}
.description .subtitle {
    max-width: 500px;
    margin: 0 auto;
}
@media only screen and (max-width: 768px) {
    .description {
        margin-top: 20px;
    }
}
</style>