<template>
  <div class="columns is-vcentered is-mobile mobile-padding">
    <div class="column is-narrow">
      <img id="marketwatch" class="partner-logo" src="@/assets/img/marketwatch_logo.png" alt="MarketWatch Logo">
    </div>
    <div class="column is-narrow is-hidden-mobile" />
    <div class="column is-narrow">
      <img id="forbes" class="partner-logo" src="@/assets/img/forbes_logo.png" alt="Forbes Logo">
    </div>
    <div class="column is-narrow is-hidden-mobile hide-overflow" />
    <div class="column is-narrow hide-overflow">
      <img class="partner-logo" style="marginTop: 10px" src="@/assets/img/entrepreneur_logo.png" alt="Entrepreneur Logo">
    </div>
    <div class="column is-narrow is-hidden-touch" />
    <div class="column is-narrow is-hidden-touch">
      <img id="seedtable" class="partner-logo" src="@/assets/img/seedtable_logo.png" alt="Seedtable Logo">
    </div>
    <div class="column is-narrow is-hidden-touch is-hidden-desktop-only" />
    <div class="column is-narrow is-hidden-touch is-hidden-desktop-only">
      <img class="partner-logo" src="@/assets/img/cointelegraph_logo.png" alt="Cointelegraph Logo">
    </div>
  </div>
</template>

<script>
export default {
  name: "FeaturedIn",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
img.partner-logo {
    height: 40px;
}
#marketwatch.partner-logo,
#forbes.partner-logo {
    height: 30px;
}
#seedtable.partner-logo {
  height: 20px;
  margin-top: 5px;
}
.is-grey-text {
  color: #BBB;
  font-weight: 500;
  font-size: 16px;
  font-family: 'Rubik', sans-serif;
}

@media only screen and (max-width: 630px) {
  .hide-overflow {
    display: none;
  }
  .mobile-padding {
    padding-top: 20px;
  }
}
</style>
