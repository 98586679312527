<template>
  <section class="section">
    <div class="container">
      <div class="columns is-vcentered">
        <div class="column is-5">
          <figure class="image">
            <picture>
              <source type="image/webp" srcset="@/assets/img/home/highlight3.webp">
              <source type="image/png" srcset="@/assets/img/home/highlight3.png">
              <img src="@/assets/img/home/highlight3.png" :alt="$t('highlight3_img')">
            </picture>
          </figure>
        </div>
        <div class="column is-5 is-offset-1">
          <h3 class="title is-spaced title-font" v-html="$t('highlight3_h3')" />
          <p class="subtitle subtitle-spaced" v-html="$t('highlight3_p')" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
    name: "Highlight3",
}
</script>

<style scoped>
.container {
  margin-bottom: 40px;
}
</style>