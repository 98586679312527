<template>
  <section class="section">
    <div class="container">
      <div class="columns is-vcentered flip-wrapper">
        <div class="column is-5 is-offset-1">
          <h3 class="title is-spaced title-font" v-html="$t('highlight2_h3')" />
          <p class="subtitle subtitle-spaced" v-html="$t('highlight2_p')" />
        </div>
        <div class="column is-5 is-offset-1">
          <figure class="image">
            <picture>
              <source type="image/webp" srcset="@/assets/img/home/highlight2.webp">
              <source type="image/png" srcset="@/assets/img/home/highlight2.png">
              <img src="@/assets/img/home/highlight2.png" :alt="$t('highlight2_img')">
            </picture>
          </figure>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
    name: "Highlight2",
}
</script>

<style scoped>

</style>