<template>
  <section class="section">
    <div class="container">
      <div class="columns is-centered has-text-centered has-text-left-mobile unique-cta">
        <div class="column is-8 is-7-widescreen is-6-fullhd">
          <h2 class="title title-font is-spaced" v-html="$t('blockchain_highlight_h2')" />
          <p class="subtitle" v-html="$t('security_p')" />
        </div>
      </div>

      <div class="columns is-vcentered mt-30">
        <div class="column is-8">
          <figure class="image">
            <picture>
              <source type="image/webp" srcset="@/assets/img/home/laptop_phone_shot.webp">
              <source type="image/png" srcset="@/assets/img/home/laptop_phone_shot.png">
              <img src="@/assets/img/home/laptop_phone_shot.png" :alt="$t('blockchain_highlight_img')">
            </picture>
          </figure>
        </div>
        <div class="column">
          <div class="columns is-vcentered is-inline-flex">
            <div class="column">
              <b-icon icon="fas fa-check-circle" pack="fas" size="is-small" />
            </div>
            <p class="is-size-5">{{ $t('ZERO_FEES') }}</p>
          </div>
          <br>
          <div class="columns is-vcentered is-inline-flex">
            <div class="column">
              <b-icon icon="fas fa-check-circle" pack="fas" size="is-small" />
            </div>
            <p class="is-size-5">{{ $t('PERFECTLY_LIQUID') }}</p>
          </div>
          <br>
          <div class="columns is-vcentered is-inline-flex">
            <div class="column">
              <b-icon icon="fas fa-check-circle" pack="fas" size="is-small" />
            </div>
            <p class="is-size-5">{{ $t('NO_COUNTERPARTIES') }}</p>
          </div>
          <br>
          <div class="columns is-vcentered is-inline-flex">
            <div class="column">
              <b-icon icon="fas fa-check-circle" pack="fas" size="is-small" />
            </div>
            <p class="is-size-5">{{ $t('VERIFIEABLE_TRANSPARENT') }}</p>
          </div>
          <br>
          <div class="columns is-vcentered is-inline-flex">
            <div class="column">
              <b-icon icon="fas fa-check-circle" pack="fas" size="is-small" />
            </div>
            <p class="is-size-5">{{ $t('SECURED_WITH_BLOCKCHAIN') }}</p>
          </div>
          <br>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
	name: "BlockchainHighlight"
};
</script>

<style scoped>
.section {
	/* background-color: #f9f8f9; */
	background-color: #f7fafc;
	padding-top: 80px;
	padding-bottom: 100px;
}
.unique-cta .title {
	margin-bottom: 1rem;
}
.mt-30 {
	margin-top: 30px;
}
</style>